<template>
  <v-row align="center" justify="center">
    <v-col cols="12" class="px-0 pb-0" v-if="loading">
      <v-skeleton-loader type="card-heading" />
    </v-col>
    <template v-else>
      <v-col class="pb-0 px-0" cols="12">
        <v-toolbar color="transparent" flat height="55">
          <span class="subtitle-1 font-weight-medium">Mis Dashboards</span>
          <v-icon class="ml-1" color="secondary">mdi-chevron-right</v-icon>
          <v-hover v-slot="{ hover }">
            <div class="ml-2 routerLink subtitle-1 font-weight-medium d-inline-block text-truncate" absolute outlined text @click="modalDashboard=true" :style="`${ $vuetify.breakpoint.width < 1400 ? 'max-width: 200px;' : 'max-width: 500px;' } cursor: pointer;`">{{instance.name}}
              <v-icon class="pl-1" small color="primary" v-if="hover">mdi-pencil</v-icon>
            </div>
          </v-hover>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-tooltip color="bgSearch" bottom max-width="280px" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" bottom color="secondary" small>mdi-information</v-icon>
                </template>
                <span class="d-block px-3 py-2">{{ (instance.description || 'Sin descripción') }}</span>
              </v-tooltip>

              <v-btn class="ml-4" @click="saveDashboard()" color="primary" height="30" :disabled="!unSaved && !((draft || {}).layout || []).length" :loading="loadingSave">Guardar</v-btn>

              <v-btn class="ml-2" @click="$router.push({name: 'MetricList', query: {dash: $route.params.id} }).catch(err => err)" outlined color="secondary" height="34"><v-icon left small>mdi-plus</v-icon> Agregar métricas</v-btn>

              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="ml-2" v-bind="attrs" v-on="on" min-width="34" max-width="34" outlined color="secondary" :ripple="false" retain-focus-on-click><v-icon color="secondary">mdi-dots-horizontal</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-5 py-1" @click="modalDestroy=true" :ripple="false">
                    <v-list-item-title class="subtitle-2 red--text font-weight-medium">Eliminar dashboard</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>

      <!-- grid -->
      <v-col class="pt-0 px-0" cols="12" v-if="layout.length">
        <grid-layout :class="!activeGrid ? '' : 'grid-layout--active-mask'" @layout-updated="layoutUpdatedEvent" :layout.sync="layout" :col-num="colNum" :row-height="30" :is-draggable="draggable" :is-resizable="false" :vertical-compact="true" :margin="[15, 20]" :use-css-transforms="true">
          <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i" @move="moveEvent" @moved="movedEvent" drag-allow-from=".vue-draggable-handle">

            <!-- type kpi -->
            <template v-if="item.metrics.type.name === 'kpi'">
              <metric-kpi @destroy="removeItemLayout(item.i)" :metric="item.metrics.name" :draggable="true" :interval="item.metrics.interval" :comparative="item.metrics.comparative" :removable="true" />
            </template>
            <!-- end type kpi -->

            <!-- graph -->
            <template v-else-if="item.metrics.type.name === 'graph'">
              <metric-graph @destroy="removeItemLayout(item.i)" @resize="expandItemLayout(item)" :draggable="true" :metric="item.metrics.name" :removable="true" :resizable="true" :settings="{i: item.i, w: item.w}" />
            </template>
            <!-- end graph -->

            <!-- table -->
            <template v-if="item.metrics.type.name === 'table'">
              <metric-table @destroy="removeItemLayout(item.i)" @resize="expandItemLayout(item)" :draggable="true" :metric="item.metrics.name" :removable="true" :resizable="true" :settings="{i: item.i, w: item.w}" />
            </template>
            <!-- end table -->

          </grid-item>
        </grid-layout>
      </v-col>
      <!-- end grid -->

      <!-- empty -->
      <v-col cols="12" class="px-0 py-16 text-center" v-else>
        <v-icon color="secondary" size="70">mdi-view-dashboard-outline</v-icon>
        <span class="d-block subtitle-1">Aún no has añadido métricas al dashboards</span>
        <v-btn class="ml-2 mt-5" @click="$router.push({name: 'MetricList', query: {dash: $route.params.id} })" outlined color="secondary" height="34">Añadir métricas</v-btn>
      </v-col>
      <!-- end empty -->

    </template>

    <!-- new metrics dialog -->
    <v-dialog v-model="modalDashboard" width="600" persistent no-click-animation overlay-color="overlay">
      <dialog-add-info-dashboard @close="modalDashboard=false;" :isCurrentDashboard="instance.id" />
    </v-dialog>
    <!-- end new metrics dialog -->

    <!-- modal delete dashboard -->
    <v-dialog v-model="modalDestroy" width="500" persistent no-click-animation overlay-color="overlay">
      <v-card flat>
        <v-toolbar flat height="55">
          <v-toolbar-title class="subtitle-1 text--secondary font-weight-medium">Eliminar dashboard</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn @click="modalDestroy=false" icon small retain-focus-on-click><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-2 py-6">
          <v-col cols="12">
            <span>Estás a punto de eliminar <span class="font-weight-medium"> {{instance.name}}</span>. Utiliza el siguiente campo de texto para confirmar el nombre del dashboard que quieres eliminar.
            </span>
            <span class="d-block mt-3">
              Escribe <span class="font-weight-medium">{{instance.name}}</span> para eliminar el dashboard
              <v-text-field v-model="deleteDashboard" outlined required single-line dense maxlength="80" hide-details autocomplete="off" />
            </span>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="modalDestroy=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="destroy" color="red darken-2" :dark="!isDashboardDelete" :disabled="isDashboardDelete">Eliminar dashboard</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end modal delete dashboard -->

  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { GridLayout, GridItem } from 'vue-grid-layout'
import cloneDeep from 'lodash/cloneDeep'
import DialogAddInfoDashboard from '@/components/DialogAddInfoDashboard'
import GenericViewMixin from '@/mixins/GenericViewMixin'

import MetricGraph from '@/modules/reports/components/MetricGraph'
import MetricKpi from '@/modules/reports/components/MetricKpi'
import MetricTable from '@/modules/reports/components/MetricTable'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    GridLayout,
    GridItem,
    MetricGraph,
    MetricKpi,
    MetricTable,
    DialogAddInfoDashboard
  },
  data: () => ({
    dashboard: {
      name: '',
      description: '',
      isMain: false
    },
    selectedIndex: 0,
    layout: [],
    table: [],
    deleteDashboard: '',
    goToDashboard: {},
    draggable: true,
    colNum: 12,
    index: 0,
    activeGrid: false,
    modalDashboard: false,
    modalDestroy: false,
    unSaved: false,
    loadingSave: false,
    loading: false,
    metric: []
  }),
  computed: {
    ...mapState({
      instance: state => cloneDeep(state.dashboards.dashboardsDetail),
      draft: state => state.dashboards.draft
    }),
    isDashboardDelete () {
      return this.instance.name !== this.deleteDashboard
    }
  },
  created () {
    this.unSaved = false
    this.loading = true

    this.$store.dispatch('dashboards/RETRIEVE', {
      resource: 'metrics/dashboards',
      id: this.$route.params.id
    })
    .then(() => {
      this.setLayout()
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      this.loading = false
    })
  },
  methods: {
    setLayout () {
      this.layout = cloneDeep(this.instance.layout)

      if (this.instance?.layout?.length) {
        this.layout.forEach((item, index) => {
          this.layout[index].metrics = item.metrics
        })
      }

      if (this.draft?.layout?.length) {
        this.draft.layout.forEach(item => {
          if (item.type.name === 'kpi') {
            this.layout.push({
              x: (this.layout.length * 3) % (this.colNum || 12),
              y: this.layout.length + (this.colNum || 12),
              w: 3,
              h: 3,
              i: (item.name + '_' + (this.layout.length + 1)),
              metrics: item
            })
          } else {
            this.layout.push({
              x: (this.layout.length * 12) % (this.colNum || 12),
              y: this.layout.length + (this.colNum || 12),
              w: 12,
              h: 6,
              i: (item.name + '_' + (this.layout.length + 1)),
              metrics: item
            })
          }
        })
        this.unSaved = true
      }
    },
    movedEvent () {
      this.activeGrid = false
      this.unSaved = true
    },
    moveEvent () {
      this.activeGrid = true
    },
    layoutUpdatedEvent () {
      this.activeGrid = false
    },
    removeItemLayout (val) {
      const index = this.layout.map(item => item.i).indexOf(val)
      this.layout.splice(index, 1)
      this.unSaved = true
    },
    expandItemLayout (val) {
      const current = this.layout.map(item => item.i).indexOf(val.i)

      if (val.w === 12) {
        this.layout[current].w = 6
      } else {
        let array = []
        let tableOrGraph = ''
        this.layout[current].w = 12

        for (let item in this.layout) {
          if (((this.layout[item].y === val.y))) {
            this.layout[current].x = 0

            if ((this.layout[item].i !== val.i)) {
              this.layout[current].x = 0
              array.push(this.layout[item])
            }
          } else if ((this.layout[item].y === (val.y - 3) || this.layout[item].y === (val.y + 3))) {
            if ((this.layout[item].metrics.type.name !== 'kpi')) {
              tableOrGraph = this.layout[item]
            } else {
              this.metric.push(this.layout[item])
            }
          }

          for (let y in array) {
            if (this.layout[item].y === array[y].y) {
              this.layout[item].y = this.layout.length * this.layout[this.layout.length - 1].h + (this.colNum || 12)
              this.layout[item].x = (this.layout.length * this.layout[item].w) % (this.colNum || 12)
            }
          }
        }

        for (let item in this.metric) {
          if (tableOrGraph.y === this.metric[item].y) {
            this.layout[item].y = this.layout.length * this.layout[this.layout.length - 1].h + (this.colNum || 12)
            this.layout[item].x = (this.layout.length * this.layout[item].w) % (this.colNum || 12)
          }
        }
      }

      this.unSaved = true
    },
    saveDashboard () {
      this.loadingSave = true
      this.$store.dispatch('dashboards/CLEAR_DASHBOARD')

      const payload = Object.assign({}, this.instance)

      payload.layout = cloneDeep(this.layout)
      payload.layout.forEach((metric) => {
        Object.keys(metric.metrics).forEach((key) => {
          if (['id', 'values', 'description'].includes(key)) {
            delete metric.metrics[key]
          }
        })
      })

      this.$store.dispatch('dashboards/UPDATE', {
        resource: 'metrics/dashboards',
        id: this.instance.id,
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('Dashboard guardado correctamente')
        this.loadingSave = false
        this.unSaved = false
      })
      .catch((error) => {
        console.error(error)
      })
    },
    destroy () {
      if (this.instance.name === this.deleteDashboard) {
        this.$store.dispatch('dashboards/LIST', {
          resource: 'metrics/dashboards'
        })
        .then((response) => {
          const data = cloneDeep(response.data.results)

          if (data.length) {
            this.goToDashboard = data.filter(dashboard => dashboard.id !== this.instance.id)
          }

          this.$store.dispatch('dashboards/DESTROY', {
            resource: 'metrics/dashboards',
            id: this.instance.id
          })
          .then(() => {
            if (this.goToDashboard.length) {
              this.$router.push({
                name: 'DashboardUpdate',
                params: {
                  id: this.goToDashboard[0].id
                }
              })
            } else {
              this.$router.push('/dashboards').catch(err => err)
            }
          })
          .catch((error) => {
            this.$dialog.message.error(this.parseErrors(error.response.data))
          })
          .finally(() => {
            this.modalDestroy = false
          })
        })
      }
    },
    dialogRouteLeave () {
     return this.$dialog.warning({
        title: 'Cambios sin guardar',
        text: `
          <span class="d-block my-2">Hay cambios que no han sido guardados</span>
          <span class="d-block my-2">¿Estás seguro de continuar?</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            class: 'mx-12'
          },
          {
            color: '',
            text: 'Cancel'
          }
        ]
      })
    }
  },
  async beforeRouteUpdate (to, from, next) {
    if (this.unSaved && (to.name === 'DashboardUpdate' && to.params?.id !== from.params?.id)) {
      const dialog = await this.dialogRouteLeave()

      if (dialog === 'Continuar') {
        this.$store.dispatch('dashboards/CLEAR_DASHBOARD')
        next()
        this.unSaved = false
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (!this.unSaved) {
      next(true)
    } else {
      const dialog = await this.dialogRouteLeave()

      if (dialog === 'Continuar') {
        this.$store.dispatch('dashboards/CLEAR_DASHBOARD')
        next(true)
        this.unSaved = false
      } else {
        next(false)
      }
    }
  },
  validations: {
    dashboard: {
      name: {
        required,
        maxLength: maxLength(56)
      },
      description: {
        maxLength: maxLength(200)
      }
    }
  }
}
</script>
<style scoped>
.grid-layout--active-mask {
  background-image: repeating-linear-gradient(90deg, transparent, transparent 25px, rgba(0,0,0,0.03) 0, rgba(0,0,0,0.03) 8.26%);
  height: 100%;
  min-height: 100vh !important;
  min-width: 900px;
}
::v-deep .vue-grid-item.vue-grid-placeholder {
  background: #3F51B5 !important;
  border-radius: 4px;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.vue-grid-layout {
  background-color: #E8EDF2;
  background-position: -6px 0;
  height: 100%;
  min-width: 900px;
  min-height: 100vh !important;
  position: relative;
  min-height: calc(100vh - 135px);
  touch-action: none;
}
</style>